import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import styles from "./styles.css";
// import { WindowSize } from '@Modules';

export default function Modal({
  data,
  title,
  show = false,
  children,
  onClose = () => {},
  onClosed = () => {},
  handleSubmit = () => {},
  fields = [],
  Page = "Standard",
  handelBtnPrimarySubmit = () => {},
  handelBtnSecondarySubmit = () => {},
}) {
  // const device = WindowSize();

  const modal = useRef();
  const modalContainer = useRef();
  const [isBrowser, setIsBrowser] = useState(false);
  const [_show, setShow] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0); // Added state for scroll position
  // const DeviceSize = WindowSize();

  const DeviceSize = {
    width: 800,
  };

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    if (show) {
      setScrollPosition(window.pageYOffset); // Store the current scroll position
      setShow(true);
      document.body.style.overflow = "hidden"; // Disable scrolling when the modal is open
    } else {
      document.body.style.overflow = ""; // Re-enable scrolling when the modal is closed
      gsap.to(modalContainer.current, {
        opacity: 0,
        y: "50%",
        scale: 0.8,
        duration: 0.4,
        onComplete: () => {
          setShow(false);
          onClosed();
        },
      });
    }
  }, [show]);

  useEffect(() => {
    if (Page == "Standard") {
      if (_show) {
        gsap.fromTo(
          modalContainer.current,
          { autoAlpha: 0, y: "50%", height: "50%", width: "50%" },
          { autoAlpha: 1, y: 0, height: "80%", width: "80%", duration: 0.4 }
        );
      }
    } else if (Page == "Home") {
      if (_show) {
        if (DeviceSize.width >= 786) {
          gsap.fromTo(
            modalContainer.current,
            { autoAlpha: 0, y: "50%", height: "50%", width: "50%" },
            {
              autoAlpha: 1,
              y: 0,
              height: "fit-content",
              width: "98%",
              maxWidth: "700px",
              duration: 0.4,
            }
          );
        } else {
          gsap.fromTo(
            modalContainer.current,
            { autoAlpha: 0, y: "50%", height: "50%", width: "50%" },
            {
              autoAlpha: 1,
              y: 0,
              height: "fit-content",
              width: "90%",
              maxWidth: "700px",
              duration: 0.4,
            }
          );
        }
      }
    } else if (Page == "GalleryPage") {
      if (_show) {
        gsap.fromTo(
          modalContainer.current,
          { autoAlpha: 0, y: "50%", height: "50%", width: "50%" },
          {
            autoAlpha: 1,
            y: 0,
            height: "fit-content",
            width: "fit-content",
            duration: 0.4,
            border: "none",
            padding: "0px",
            maxWidth: "90%",
          }
        );
      }
    } else {
      if (_show) {
        gsap.fromTo(
          modalContainer.current,
          { autoAlpha: 0, y: "50%", height: "50%", width: "50%" },
          {
            autoAlpha: 1,
            y: 0,
            height: "fit-content",
            width: "98%",
            maxWidth: "700px",
            duration: 0.4,
          }
        );
      }
    }
  }, [_show]);

  var componentBody;
  if (Page == " ") {
    componentBody = _show ? (
      <div ref={modal} className={`modal`}>
        <div ref={modalContainer} className={`modal_container`}>
          <div className={`modal_header`}>
            <div className={`modal_header_title`}>{title}</div>
            <div className={`modal_header_close`} onClick={onClose}></div>
          </div>
          <div className={`modal_content`}>{children}</div>
        </div>
        <div className={`modal_backdrop`}></div>
      </div>
    ) : null;
  } else if (Page == "Home") {
    componentBody = _show ? (
      <div ref={modal} className={`modalHomePage`} onClick={onClose}>
        <div ref={modalContainer} className={`modalHomePage_container`}>
          <div
            className="large-size_1-of-1 size_1-of-1"
            style={{ background: "#111111" }}
          >
            <div
              className={`modalHomePage_closeButton1`}
              onClick={onClose}
            ></div>
            <a
              href="https://www.slayingthedragon.co.za/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://cdn.antonioiozzo.com/Slaying-The-Dragon/img/book/slaying-the-dragon-cover-bestselling-mockup.jpg"
                alt="Slaying the Dragon"
                style={{ paddingTop: "35px" }}
              />
            </a>
          </div>
          <a
            href="https://www.slayingthedragon.co.za/"
            target="_blank"
            rel="noreferrer"
            style={{ width: "100%" }}
          >
            <p
              style={{
                padding: "25px",
                color: "black",
                textAlign: "center",
                width: "100%",
                fontSize: "22px",
              }}
            >
              Read more about Antonio&apos;s extraordinary journey
            </p>
          </a>
        </div>
        <div className={`modalHomePage_backdrop`}></div>
      </div>
    ) : null;
  } else if (Page == "GalleryPage") {
    componentBody = _show ? (
      <div ref={modal} className={`modal`}>
        <div ref={modalContainer} className={`modal_container`}>
          <div className={`modal_header`}>
            <div className={`modal_header_title`}>{title}</div>
            <div
              className={`modalHomePage_header_closeButton`}
              style={{ marginTop: "1px", marginRight: "1px" }}
              onClick={onClose}
            ></div>
          </div>
          <div className={`modal_content`}>{children}</div>
        </div>
        <div className={`modal_backdrop`}></div>
      </div>
    ) : null;
  }

  if (isBrowser) {
    return componentBody;
  } else {
    return null;
  }
}
